import React from "react";
import { Helmet } from "react-helmet";
import Icon from "../../images/emerald_favicon.png";

const HelmetComponent = () => {
  return (
    <Helmet>
      {/* Primary Meta Tags */}
      <title>{"Hudson Samuels"}</title>
      <meta name="title" content="Hudson Samuels" />
      <meta
        name="description"
        content={
          "The official website for Hudson Samuels, a freelance full stack developer, game designer, and graphic designer from Dallas, Texas."
        }
      />
      <meta name="theme-color" content="#9266b0" />
      <link rel="icon" type="image/png" href={Icon} sizes="16x16" />
    </Helmet>
  );
};

export default HelmetComponent;
