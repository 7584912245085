export const ProjectData = [
  {
    id: "audioloader",
    subtitle: "Node.js, React, Python",
    title: "Audio Loader",
    description:
      "A Steam Deck plugin. Allows users to change Steam UI sounds and add custom music outside of a game.",
    note: "Lead Developer",
    source: "https://github.com/EMERALD0874/SDH-AudioLoader/",
    img: "audioloader.png",
    imgAlt: "Screenshot of the pack browser in Audio Loader",
    color: "#199fff",
  },
  {
    id: "bitbot",
    subtitle: "Node.js, LDAP, MongoDB",
    title: "BitBot",
    description:
      "The official Discord bot for UTDesign Makerspace. Used to check 3D printing status, receive notifications, and more.",
    note: "Lead Developer",
    website: "https://discord.gg/M7G2eFQFug",
    source: "https://github.com/utdesign-makerspace/Bit-Bot/",
    img: "bitbot.png",
    imgAlt: "Screenshot of the /status command in BitBot",
    color: "#c1393d",
  },
  {
    id: "makerspacewebsite",
    subtitle: "Node.js, Gatsby, GraphQL",
    title: "UTDesign Makerspace Website",
    description:
      "The official website for UTDesign Makerspace. Hosts a calendar, simple store, blog.",
    note: "Lead Developer",
    website: "https://utd.ms/",
    source: "https://github.com/utdesign-makerspace/Makerspace-Home/",
    img: "makerspace.png",
    imgAlt: "Screenshot of the UTDesign Makerspace homepage",
    color: "#c1393d",
  },
  {
    id: "cssloader",
    subtitle: "Node.js, React, Python",
    title: "CSS Loader",
    description:
      "A Steam Deck plugin. Allows users to add custom CSS styling to the Steam UI, such as stylizing icons or changing colors.",
    note: "Lead Developer",
    website: "https://cssloader.beebl.es/",
    source: "https://github.com/suchmememanyskill/SDH-CssLoader/",
    img: "phantom.jpg",
    imgAlt: "Screenshot of the Phantom theme I created for CSS Loader",
    color: "#992531",
  },
  {
    id: "dreadlight",
    subtitle: "Unity, C#",
    title: "Dreadlight",
    description:
      "An action game with an arcade aesthetic. Created with a small team for a game jam and won Best Overall and Best Visuals.",
    note: "Lead Developer",
    website: "https://emerald0874.itch.io/dreadlight",
    source: null,
    img: "dreadlight.png",
    imgAlt: "Screenshot of Dreadlight gameplay",
    color: "#ff5379",
  },
  {
    id: "temoc",
    subtitle: "Node.js, MongoDB, SMTP",
    title: "Temoc Bot",
    description:
      "An unofficial UT Dallas-related Discord bot. Checks the menu at Dining Hall West, parking, Rate My Professors, and more.",
    note: "Independent",
    website: "https://whoosh.page.link/temocbot",
    source: null,
    img: "temoc.png",
    imgAlt: "Screenshot of the /parking command in the Temoc bot",
    color: "#ec781d",
  },
  {
    id: "payday",
    subtitle: "Unity, C#",
    title: "PAYDAY 2 Stealth",
    description:
      "An assignment to recreate a mechanic from a game. Contains basic detection, movement, pathing, and animation.",
    note: "Independent",
    website: null,
    source: "https://github.com/EMERALD0874/PD2-Stealth-Recreation",
    img: "payday.png",
    imgAlt:
      "Screenshot of answering a pager in the PAYDAY 2 stealth recreation",
    color: "#0a9ff6",
  },
];
