import React, { useState } from "react";
import Navbar from "../components/Navbar";
import Sidebar from "../components/Sidebar";
import Hero from "../components/Hero";
import Project from "../components/Project";
import { ProjectData } from "../components/Project/ProjectData";
import SectionHeader from "../components/SectionHeader";
import { SectionContainer } from "../components/SectionContainerElement";
import Skills from "../components/Skills";

import "./style.css";
import HelmetComponent from "../components/Helmet";

const Home = () => {
  const [isOpen, setIsOpen] = useState(false);

  const toggle = () => {
    setIsOpen(!isOpen);
  };

  const projects = [];
  let projectCount = 0;

  ProjectData.forEach((project) => {
    projects.push(<Project {...project} imgLeft={projectCount % 2 === 0} />);
    projectCount++;
  });

  return (
    <>
      <HelmetComponent />
      <Sidebar isOpen={isOpen} toggle={toggle} />
      <Navbar toggle={toggle} />
      <Hero />
      <SectionContainer>
        <SectionHeader title="Skills" id="skills" />
        <Skills />
      </SectionContainer>
      <SectionContainer>
        <SectionHeader title="Projects" id="projects" />
        {projects}
      </SectionContainer>
    </>
  );
};

export default Home;
